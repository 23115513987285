import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Term: FunctionComponent = () => {
  return <div className="term">
    <header className="term__title-window">
      <FormattedMessage
        id={'gritx.terms.titleTerms'}
        defaultMessage={'Terms and Conditions'}
      />
    </header>
    <h4 className="term__title">
      {'1. Introduction & Definitions'}
    </h4>
    <ul>
      <li className="term__description">
        {'This agreement outlines the terms and conditions governing your use of the JUN™ mobile health application.'}
      </li>
      <li className="term__description">
        {'"You" or "User" refers to any individual accessing or using JUN™.'}
      </li>
      <li className="term__description">
        {'"We," "Us," or "Our" refers to University of Texas Health Science Center at San Antonio Texas, '
          + 'University of Texas Regents, and Scalable Care the developer and provider of the App.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'2. User Responsibilities'}
    </h4>
    <ul>
      <li className="term__description">
        {'You agree to use the App solely for lawful purposes and in accordance with these Terms.'}
      </li>
      <li className="term__description">
        {'You are responsible for maintaining the confidentiality of your account and password.'}
      </li>
      <li className="term__description">
        {'You are responsible for all activities that occur under your account.'}
      </li>
      <li className="term__description">
        {'You may not use the App to transmit any harmful or illegal content.'}
      </li>
      <li className="term__description">
        {'You may not interfere with the App\'s functionality or attempt to gain unauthorized access to the App or its data. '}
      </li>
    </ul>
    <h4 className="term__title">
      {'3. Content Disclaimer'}
    </h4>
    <ul>
      <li className="term__description">
        {'The App may contain general information related to health, wellness, and fitness.'}
      </li>
      <li className="term__description">
        {'This information is for general informational purposes only and should not be construed as medical advice.'}
      </li>
      <li className="term__description">
        {'Always consult with a qualified healthcare professional for any health concerns or before making any decisions related to your health.'}
      </li>
      <li className="term__description">
        {'We are not responsible for any reliance on the content provided within the App.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'4. Intellectual Property'}
    </h4>
    <ul>
      <li className="term__description">
        {'JUN™ and its content, including but not limited to text, graphics, images, and logos, are protected by '
          + 'trademark, copyright, and other intellectual property laws.'}
      </li>
      <li className="term__description">
        {'You may not reproduce, modify, distribute, or create derivative works from JUN™’s content without our express written consent.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'5. Privacy and Data Security'}
    </h4>
    <ul>
      <li className="term__description">
        {'We respect your privacy and are committed to protecting your personal information.'}
      </li>
      <li className="term__description">
        {'Please refer to our separate Privacy Policy for more information on how we collect, use, and protect your personal data.'}
      </li>
      <li className="term__description">
        {'We may collect and use your data to provide you with a personalized experience and to improve JUN™.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'6. Disclaimer of Warranties'}
    </h4>
    <ul>
      <li className="term__description">
        {'JUN™ is provided on an "as is" and "as available" basis without any warranties, express or implied.'}
      </li>
      <li className="term__description">
        {'We do not guarantee the accuracy, completeness, or reliability of the information provided within JUN™.'}
      </li>
      <li className="term__description">
        {'We shall not be liable for any damages arising from your use of JUN™ or reliance on its content.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'7. Limitation of Liability'}
    </h4>
    <ul>
      <li className="term__description">
        {'To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, consequential, '
          + 'or special damages arising from your use of JUN™.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'8. Governing Law and Jurisdiction'}
    </h4>
    <ul>
      <li className="term__description">
        {'These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of Texas.'}
      </li>
      <li className="term__description">
        {'Any dispute arising out of or relating to these Terms and Conditions shall be subject to the exclusive '
          + 'jurisdiction of the courts in San Antonio, Texas.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'9. Termination'}
    </h4>
    <ul>
      <li className="term__description">
        {'We may terminate your access to JUN™ at any time for any reason.'}
      </li>
      <li className="term__description">
        {'You may terminate your use of JUN™ at any time by uninstalling JUN™  from your device.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'10. Amendments'}
    </h4>
    <ul>
      <li className="term__description">
        {'We reserve the right to modify these Terms and Conditions at any time.'}
      </li>
      <li className="term__description">
        {'We will notify you of any material changes to these Terms and Conditions.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'11. Entire Agreement'}
    </h4>
    <ul>
      <li className="term__description">
        {'These Terms and Conditions constitute the entire agreement between you and us with respect to the App and '
          + 'supersede any prior or contemporaneous communications or agreements.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'12. Contact Information'}
    </h4>
    <ul>
      <li className="term__description">
        {'If you have any questions or concerns regarding these Terms and Conditions, please contact us at '}
        <a href={'mailto:crawfordad@uthscsa.edu'} className={'term__list__item__description'}>
          {'crawfordad@uthscsa.edu'}
        </a>
      </li>
    </ul>
  </div>;
};

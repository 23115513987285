import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Privacy: FunctionComponent = () => {
  return <div className="privacy">
    <header className="privacy__title-window">
      {'Security Statement JUN™'}
    </header>
    <p className="privacy__description">
      {'JUN™ is an “Investigational” device meaning that the device has not yet been approved by the '
        + 'U.S. Food & Drug Administration (FDA) for treating/preventing/diagnosing health conditions. '
        + 'Registering for an account within the JUN™ application means your data with name, phone number, '
        + 'email, zip codes and dates will be sent to the University of Texas Health Science Center at San Antonio (UTHSCSA) and Scalable Care.  '
        + 'UTHSCSA is developing the JUN™ app and assisting with the analysis of the information. '
        + 'Scalable Care (TM) is a digital care platform that uses conversational artificial intelligence and advanced science '
        + 'to enable organizations to measure the delivery of healthcare. Interaction with the JUN™ app and self-reporting '
        + 'symptoms are voluntary. We have put in place technical, physical, and administrative safeguards '
        + 'to protect the Personal Information that we collect.'}
    </p>
    <p className="privacy__description">
      {'Information inputted into the JUN™ app will be handled in a confidential manner, within the limits of the law.'}
    </p>
    <p className="privacy__description">
      {'Even without your consent, suspected or known abuse or neglect of a child, disabled, or elder abuse, '
        + 'threatened violence to self or others or other local health reporting requirements will be reported to appropriate authorities. '
        + 'Research policies require that private information about you be protected, and this is especially true for your health information. '
        + 'However, the law sometimes allows or requires others to see your information. '
        + 'The information given below describes how your privacy and the confidentiality of your research records '
        + 'will be protected in this study. Protected Health Information is information about a person’s health '
        + 'that includes information that would make it possible to figure out who it is. '
        + 'According to the law, you have the right to decide who can see your protected health information. '
        + 'We will not share your information: We will never sell, rent, or lease your information, '
        + 'and we will never voluntarily share identifying information about you without your permission. '
        + 'If you do not want your Personal Information used by JUN™ as provided in this Privacy Policy, '
        + 'you should not visit any specific tool or application that collects Personal Information including use. '
        + 'We may use information we collect about you to, (1) provide you with access to particular tools and services; '
        + '(2) Obtain your feedback on our site’s functionality; (3) Provide you with more relevant content.'}
    </p>
  </div>;
};

import {IAction} from 'redux/interface';
import {IAvatar, IUser} from './interfaces';
import {InferActionsTypes} from 'redux/helpers';
import {SystemLanguage} from 'utils/constants/constants';
import {IUserProfile} from '@wholesalechange/chatcomponent';
import {ILocation} from '../content/interfaces';

export const SET_USER = 'SET_USER';
export const ERROR_LOGIN_SET = 'ERROR_LOGIN_SET';
export const ERROR_LOGIN_CLEAR = 'ERROR_LOGIN_CLEAR';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_BOT_AVATARS = 'SET_BOT_AVATARS';
export const SET_USER_AVATARS = 'SET_USER_AVATARS';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_BOT_AVATAR = 'UPDATE_BOT_AVATAR';
export const FINISH_AUTHORIZATION = 'FINISH_AUTHORIZATION';
export const START_RELOGIN = 'START_RELOGIN';

export const loginErrorActionSet = (): IAction<boolean> => ({
  type: ERROR_LOGIN_SET,
  payload: true
});

export const loginErrorActionClear = (): IAction<boolean> => ({
  type: ERROR_LOGIN_CLEAR,
  payload: false
});

export const setUserAction = (user: IUser): IAction<IUser> => ({
  type: SET_USER,
  payload: user
});

export const setUserProfileAction = (userProfile: IUserProfile): IAction<IUserProfile> => ({
  type: SET_USER_DATA,
  payload: userProfile
});

export const setBotAvatarsAction = (botAvatars: IAvatar[]): IAction<IAvatar[]> => ({
  type: SET_BOT_AVATARS,
  payload: botAvatars
});

export const setUserAvatarsAction = (userAvatars: IAvatar[]): IAction<IAvatar[]> => ({
  type: SET_USER_AVATARS,
  payload: userAvatars
});

export const setUserLanguageAction = (data: IUserProfile): IAction<IUserProfile> => ({
  type: SET_USER_DATA,
  payload: {
    ...data,
    language: data.language || SystemLanguage.English
  }
});

export const clearUserAction = (): IAction<null> => ({
  type: SET_USER,
  payload: null
});

export const updateUserAvatarAction = (avatar: IAvatar): IAction<IAvatar> => ({
  type: UPDATE_USER_AVATAR,
  payload: avatar
});

export const updateBotAvatarAction = (avatar: IAvatar): IAction<IAvatar> => ({
  type: UPDATE_BOT_AVATAR,
  payload: avatar
});
export const finishAuthorizationAction = (): IAction<null> => ({
  type: FINISH_AUTHORIZATION,
  payload: null
});
export const startReloginAction = (): IAction<null> => ({
  type: START_RELOGIN,
  payload: null
});

const authActions = {
  loginErrorActionSet,
  loginErrorActionClear,
  setUserAction,
  clearUserAction,
  setUserProfileAction,
  setBotAvatarsAction,
  setUserAvatarsAction,
  setUserLanguageAction,
  updateUserAvatarAction,
  updateBotAvatarAction
};

export type AuthActionsType = InferActionsTypes<typeof authActions>

import React, {useEffect, useState} from 'react';
import {IJournalStatisticValue} from '../../../redux/journal-stats/interfaces';
// import {PieChart} from '@mui/x-charts/PieChart';
// import {PieValueType} from '@mui/x-charts';
// import {MakeOptional} from '@mui/x-charts/models/helpers';

import './styles.scss';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

interface IJournalStatValues {
  values: IJournalStatisticValue[]
  name: string
}

interface IChartData {
  id: number
  value: number
  label: string
}

export const JournalPieChart: React.FunctionComponent<IJournalStatValues> = ({values, name}: IJournalStatValues) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const COLORS = ['#c1e6d9', '#78c7eb', '#eba378', '#5b5aa6', '#84a58b', '#f8f0cc', '#d66027'];

  useEffect(() => {
    setChartData(values
      .map((v, i) => {
        return {
          id: i,
          value: v.value,
          label: v.xscName
        };
      }));
  }, [values]);

  return <div className="stats__values-pie-chart">
    <div className="stats__values-pie-chart-name">{name}</div>
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          nameKey="label"
          label={(p) => p.value ? p.value.toString() : ''}
          labelLine={false}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend align={'right'} verticalAlign={'middle'} layout={'vertical'}/>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
    {/*<PieChart*/}
    {/*  colors={['#c1e6d9', '#78c7eb', '#eba378', '#5b5aa6', '#84a58b', '#f8f0cc', '#d66027']}*/}
    {/*  series={[*/}
    {/*    {*/}
    {/*      data: chartData,*/}
    {/*      arcLabel: (p) => p.value ? p.value.toString() : ''*/}
    {/*    }*/}
    {/*  ]}*/}
    {/*  margin={{top: 10, bottom: 10}}*/}
    {/*  height={300}*/}
    {/*/>*/}
  </div>;
};

import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import Input from 'components/input';
import TextBox from 'components/text-box';
import FileUploader from 'components/file-uploader';
import FilePreview from 'components/file-preview';
import Loader from 'components/loader';
import {removeAttachAction} from 'redux/feedback/actions';
import {uploadAttachAction} from 'redux/feedback/feedbackAsyncActions';

import {IStore} from 'redux/interface';

import {IIssueFormModel} from './interfaces';

import './style.scss';
import {FileWithPath} from 'react-dropzone';

interface IIssueForm {
  title: string
  subtitle?: string
  textBoxLabel?: string
  showTextBox?: boolean
  showAttachments?: boolean
  onChange?: (newState: IIssueFormModel) => void
}

export default function IssueForm({
  title,
  subtitle,
  textBoxLabel,
  onChange,
  showTextBox = false,
  showAttachments = false
}: IIssueForm): JSX.Element {
  const intl = useIntl();
  const {
    auth: {userProfile},
    status: {loading}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  const [data, setData] = useState<IIssueFormModel>({
    email: userProfile?.email || '',
    issue: '',
    files: []
  });

  function handleChange(type: string): (e: string | File[]) => void {
    return (e) => {
      const newState = {
        ...data,
        [type]: e
      };

      if (onChange) {
        onChange(newState);
      }
      setData(newState);
    };
  }

  function handleChangeFile(files: readonly FileWithPath[]): void {
    const filesWithKeys = files.map((file) => {
      return {
        key: Number(String(Math.random())
          .split('.')[1] || 0),
        file
      };
    });

    filesWithKeys.forEach(file => dispatch(uploadAttachAction(file)));
    const newState = {
      ...data,
      files: [...data.files, ...filesWithKeys]
    };

    if (onChange) {
      onChange(newState);
    }
    setData(newState);
  }

  function handleDeleteFile(key: number): () => void {
    return () => {
      dispatch(removeAttachAction(key));

      const newState = {
        ...data,
        files: [
          ...data.files.filter(item => item.key !== key)
        ]
      };

      if (onChange) {
        onChange(newState);
      }
      setData(newState);
    };
  }

  return <div className={'form-issue'}>
    <div className="form-issue__header">
      <h2 className="form-issue__title">{title}</h2>
      {subtitle && <p className="form-issue__subtitle">{subtitle}</p>}
    </div>
    <div className="form-issue__body">
      <Input
        required
        type="email"
        label={intl.formatMessage({
          id: 'gritx.footer.issueForm.email.label',
          defaultMessage: 'Email Address'
        })}
        value={data.email}
        onChange={handleChange('email')}
      />
      {
        showTextBox && <TextBox
          onChange={handleChange('issue')}
          required
          label={textBoxLabel}
        />}
      {showAttachments && <FileUploader
        onChange={handleChangeFile}
        label={intl.formatMessage({
          id: 'gritx.footer.issueForm.attachments.label',
          defaultMessage: 'Attachments'
        })}
      />}
      <div className="form-issue__preview">
        {
          data.files.map((item) => {
            return <div key={item.key} className="form-issue__preview-wrapper">
              {
                loading.includes(item.key.toString()) && <Loader nested/>
              }
              <FilePreview
                file={item.file}
                onDelete={handleDeleteFile(item.key)}
              />
            </div>;
          })
        }
      </div>
    </div>
  </div>;
}
